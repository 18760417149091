<template>
  <v-card>
    <v-card-text>
      <v-container>
        <validation-observer ref="observer">
          <v-row>
            <v-col cols="12" sm="12" md="4">
              <div
                class="preview d-flex align-start py-16"
                :class="{ 'preview--background': urlImagemPreview }"
              >
                <div>
                  <img
                    v-if="urlImagemPreview"
                    :src="urlImagemPreview"
                    alt="image-preview"
                    class="image-preview"
                  />
                  <div v-else />
                  <v-btn @click="abrirFoto" class="button" elevation="2"
                    >Enviar imagem</v-btn
                  >
                  <input
                    type="file"
                    ref="foto"
                    @change="changeArquivo"
                    style="display: none"
                    accept="image/*"
                  />
                </div>
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="7"
              class="ma-auto align-center height--fit-content"
            >
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Nome"
                    rules="required"
                  >
                    <v-text-field
                      v-model="user.name"
                      label="Nome"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="E-mail"
                    rules="verificaEmailCadastrado"
                  >
                    <v-text-field
                      v-model="user.email"
                      label="E-mail"
                      @keyup="verificaEmail"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="CPF"
                    rules="cpfValido"
                  >
                    <v-text-field
                      v-model="user.cpf"
                      ref="cpf"
                      label="CPF"
                      v-mask="['###.###.###-##']"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <!-- <v-col cols="12" sm="12" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="CNPJ"
                    rules="cnpjValido"
                  >
                    <v-text-field
                      v-model="user.cnpj"
                      label="CNPJ"
                      v-mask="['##.###.###/####-##']"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col> -->
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="4" class="m-auto">
                  <v-checkbox
                    v-model="user.is_motorista"
                    label="Motorista"
                    class="ma-0 pa-0"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="12" md="4" class="m-auto"> </v-col>
              </v-row>
            </v-col>
          </v-row>
        </validation-observer>
      </v-container>
      <v-card-actions>
        <v-spacer />
        <buttons-cadastro @close="close" @save="save" />
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>
<script>
import { cnpj, cpf } from 'cpf-cnpj-validator'

import UsuarioApi from '../../../../services/usuario/index'
import FileApi from '../../../../services/files/index'
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro'

import { mapActions } from 'vuex'

export default {
  name: 'DadosUsuario',
  props: ['usuario', 'tipoLogin'],
  components: { ButtonsCadastro },
  data() {
    return {
      user: this.usuario,
      senhaIncorreta: '',
      urlImagemPreview: null,
    }
  },

  created() {
    if (!this.user || !this.user.avatar) return
    const fixedUrl = process.env.VUE_APP_API_URL.substring(
      0,
      process.env.VUE_APP_API_URL.length - 1
    )

    this.urlImagemPreview =
      process.env.VUE_APP_API_URL === 'http://127.0.0.1:8000/'
        ? fixedUrl + this.user.avatar.url
        : this.user.avatar.url
  },
  methods: {
    ...mapActions('usuario', ['addUsuario', 'editarUsuario']),
    ...mapActions('template', ['successMessage', 'errorMessage']),

    close: function () {
      this.$emit('close')
    },

    save: async function () {
      try {
        const copy = { ...this.user }
        const { cpf: userCPF, cnpj: userCNPJ } = copy

        if (userCPF) {
          copy.cpf = cpf.strip(userCPF)
        }
        if (userCNPJ) {
          copy.cnpj = cnpj.strip(userCNPJ)
        }

        const { avatar } = copy
        delete copy.avatar

        if (avatar && !avatar.url && typeof avatar !== 'string') {
          const formData = new FormData()
          formData.append('file', avatar)
          formData.append(
            'description',
            `Foto de perfil do usuário ${copy.name}`
          )

          const { attachment_key } = await FileApi.postFotoMotorista(formData)
          copy.avatar = attachment_key
        }

        await this.editarUsuario(copy)
        Object.assign(this.user, copy)
        this.successMessage('Colaborador(a) atualizado(a) com sucesso!')
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },

    abrirFoto() {
      this.$refs['foto'].click()
    },

    changeArquivo(e) {
      const files = e.target.files

      this.user.avatar = files[0]
      this.urlImagemPreview = URL.createObjectURL(files[0])
    },

    async verificaEmail() {
      this.$refs.observer.validate()
      this.$refs.observer.flags.invalid
    },

    async verificaEmailCadastrado(email) {
      const response = await UsuarioApi.getUsuarioByParams({ email })

      if (!response) return false

      if (Object.keys(response).length > 0) {
        return true
      }
      return false
    },

    validate() {
      this.$refs.observer.validate()
      const isValid = !this.$refs.observer.flags.invalid
      return isValid
    },
  },
}
</script>

<style lang="scss" scoped>
.preview {
  // margin: 0 auto;

  > div {
    width: 250px;
    height: 250px;
    margin: 0 auto;
    border: 3px solid #ccc;
    position: relative;
    background: #ededed;
    border-radius: 50%;
  }

  .button {
    transition: all 0.3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
    position: absolute;
    bottom: -30px;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    visibility: hidden;
  }

  & > div:hover {
    .button {
      background: #00aa9e !important;
      bottom: 30px;
      visibility: visible;
    }
  }
}

.preview--background {
  > div {
    width: 250px;
    height: 250px;
    margin: 0 auto;
    border: none;
    background: none;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.height--fit-content {
  height: fit-content;
}
</style>
