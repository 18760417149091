<template>
  <v-card>
    <form-fields-adicionar-usuario @save="save" @close="close" />
  </v-card>
</template>

<style lang="scss" scoped>
.preview {
  margin: 0 auto;

  > div {
    width: 300px;
    height: 300px;
    margin: 0 auto;
    border: 3px solid #ccc;
    position: relative;
    background: #ededed;
    border-radius: 50%;
  }

  .button {
    transition: all 0.3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
    position: absolute;
    bottom: -30px;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    visibility: hidden;
  }

  & > div:hover {
    .button {
      background: #00aa9e !important;
      bottom: 30px;
      visibility: visible;
    }
  }
}

.preview--background {
  > div {
    width: 300px;
    height: 300px;
    margin: 0 auto;
    border: none;
    background: none;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.radio-group {
  margin: 8px 0;
  .v-radio {
    width: 45%;
    margin: 0 !important;
  }
}
</style>

<script>
import UtilsApi from '@/services/utils/utils.api'

import { mapActions, mapState } from 'vuex'

import FormFieldsAdicionarUsuario from './FormFieldsAdicionarUsuario.vue'

export default {
  components: {
    FormFieldsAdicionarUsuario,
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
  },
  methods: {
    ...mapActions('gerencial', ['addColaborador']),
    ...mapActions('template', ['errorMessage', 'successMessage']),

    async save({ user, grupo, perfis }) {
      try {
        await this.addColaborador({ user, grupo, perfis })
        this.successMessage('Colaborador(a) adicionado(a) com sucesso!')
        this.close()
      } catch (err) {
        this.errorMessage(UtilsApi.traduzirCamposInvalidos(err.response.data))
      }
    },

    close() {
      this.$emit('close')
    },
  },
}
</script>
