<template>
  <v-card class="text-center">
    <v-tabs icons-and-text v-model="tab">
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#dados">
        Dados do(a) Colaborador(a) <v-icon>mdi-account-edit</v-icon></v-tab
      >
      <v-tab href="#empresas">
        Segurança <v-icon>mdi-shield-account</v-icon></v-tab
      >

      <v-tabs-items v-model="tab">
        <v-tab-item value="dados">
          <dados-usuario :usuario="colaborador.user" @close="cancelar"/>
        </v-tab-item>
        <v-tab-item value="empresas">
          <dados-colaborador-seguranca v-model="colaborador" @close="cancelar" />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-card>
</template>

<script>
import DadosUsuario from './DadosUsuario.vue'
import DadosColaboradorSeguranca from './DadosColaboradorSeguranca.vue'

import { mapActions } from 'vuex'

export default {
  props: ['colaborador'],
  components: { DadosUsuario, DadosColaboradorSeguranca },
  data() {
    return {
      empresas: [],
      empresasSelecionadas: [],
      tab: null,
    }
  },
  methods: {
    ...mapActions('usuario', ['editarUsuario']),

    async save() {
    },

    cancelar() {
      this.$emit('close')
    },
  },
}
</script>
