<template>
  <div>
    <div>
      <v-tabs v-model="tabs">
        <v-tab href="#ativos">Ativos</v-tab>
        <v-tab href="#naoativos">Não ativos</v-tab>
        <v-tabs-items v-model="tabs">
          <v-tab-item value="ativos">
            <div class="mt-4">
              <v-card-title v-if="canList">
                Filtro
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisa" single-line
                  hide-details></v-text-field>
              </v-card-title>
              <v-data-table :headers="headers" :items="listaUsuarios ? listaUsuarios : []" :search="search"
                sort-by="descricao" class="border">
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <v-toolbar-title>Colaboradores registrados</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-icon v-if="canList" title="Atualizar" medium @click="atualizarListagem">mdi-refresh</v-icon>
                    <v-spacer></v-spacer>
                    <v-btn v-if="canCreate" color="primary" dark class="mb-2" @click="adicionarColaborador">Novo(a)
                      Colaborador(a)</v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon v-if="canUpdate" title="Editar Colaborador" small class="mr-2"
                    @click="editarColaborador(item)">mdi-pencil</v-icon>
                  <v-icon v-if="canDelete && podeRemoverColaborador(item)" title="Inativar Colaborador" small
                    @click="handleAction(item, 'desativar')">mdi-delete</v-icon>
                </template>
                <template #no-data v-if="!canList">
                  <span class="red--text">Você não tem permissão para visualizar as informações desta
                    tela</span>
                </template>
              </v-data-table>
            </div>
          </v-tab-item>
          <v-tab-item value="naoativos">
            <div class="mt-4">
              <v-card-title v-if="canList">
                Filtro
                <v-spacer></v-spacer>
                <v-text-field v-model="search1" append-icon="mdi-magnify" label="Pesquisa" single-line
                  hide-details></v-text-field>
              </v-card-title>
              <v-data-table :headers="headers" :items="listaUsuariosInativos ? listaUsuariosInativos : []"
                :search="search1" sort-by="descricao" class="border">
                <template v-slot:[`item.tipoTarifa`]="{ item }">
                  <span style="text-transform: capitalize">{{
                    item.tipoTarifa
                  }}</span>
                </template>
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <v-toolbar-title>Colaboradores não ativos</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-icon v-if="canList" title="Atualizar" medium @click="atualizarListagem">mdi-refresh</v-icon>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon v-if="!item.default && canDelete" title="Ativar Colaborador" small
                    @click="handleAction(item, 'ativar')">mdi-undo-variant</v-icon>
                </template>
              </v-data-table>
            </div>
            <v-dialog v-model="dialogDelete" max-width="400px">
            </v-dialog>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
    <confirm-dialog @confirm="confirm" v-if="canDelete" />
  </div>
</template>

<script>
import { cpf } from 'cpf-cnpj-validator'
import ConfirmDialog from '@/components/template/ConfirmDialog'
import { BasicPermissions, Sequences } from '@/utils/permissions'

import { mapActions, mapState } from 'vuex'
export default {
  // router param
  props: ['empresa'],

  components: { ConfirmDialog },

  data() {
    return {
      dialogDelete: false,
      dialog: false,
      usuarioIdDelete: -1,
      search: '',
      search1: '',
      headers: [
        {
          text: 'E-mail/Usuário',
          align: 'start',
          value: 'user.email',
        },
        { text: 'Nome', value: 'user.name' },
        { text: 'Grupo', value: 'name_grupo' },
        { text: 'E-mail', value: 'user.email' },
        { text: 'CPF', value: 'user.cpf' },
        { text: 'CNPJ', value: 'user.cnpj' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      tabs: null,

      confirmDialogItem: null,
      confirmDialogAction: '',
      itemDelete: {},
      isEdit: false,
      header: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Descrição', value: 'descricao' },
        { text: 'Tipo de tarifa', value: 'tipoTarifa' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        first_name: '',
        last_name: '',
      },
      defaultItem: {
        first_name: '',
        last_name: '',
      },
      itemToActive: null,
      itemToDelete: null,
    }
  },
  computed: {
    ...mapState('gerencial', ['colaboradores', 'colaboradoresInativos']),
    ...mapState('auth', ['user']),

    permission() {
      return Sequences.Colaboradores.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },

    formTitle() {
      return this.editedIndex === -1 ? 'Novo usuário' : 'Editar usuário'
    },

    listaUsuarios() {
      return this.colaboradores.map((usuario) => {
        usuario.user.cpf = cpf.format(usuario.user.cpf)
        return usuario
      })
    },

    listaUsuariosInativos() {
      return this.colaboradoresInativos.map((usuario) => {
        usuario.user.cpf = cpf.format(usuario.user.cpf)
        return usuario
      })
    },
  },

  async created() {
    if (this.canList) {
      if (this.empresa) {
        await this.getColaboradoresDaEmpresa(this.empresa)
      } else {
        await this.getColaboradores()
        await this.getColaboradoresInativos()
      }
    }
  },


  methods: {
    ...mapActions('gerencial', [
      'getColaboradores',
      'getColaboradoresInativos',
      'getColaboradoresDaEmpresa',
      'desativarColaborador',
      'ativarColaborador',
    ]),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),

    podeRemoverColaborador(colaborador) {
      return this.user.public_id !== colaborador.user.public_id
    },

    async confirm() {
      try {
        const nomeColaborador = this.confirmDialogItem.user.name
        switch (this.confirmDialogAction) {
          case 'ativar':
            await this.ativarColaborador(this.confirmDialogItem)
            this.successMessage(`Colaborador(a) ${nomeColaborador} foi ativado(a) com sucesso!`)
            break
          default:
            await this.desativarColaborador(this.confirmDialogItem)
            this.successMessage(`Colaborador(a) ${nomeColaborador} foi inativado(a) com sucesso!`)
        }
        await this.atualizarListagem()
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },

    adicionarColaborador() {
      this.$emit('showForm')
    },

    editarColaborador(colaborador) {
      this.$emit('editColaborador', colaborador)
    },

    atualizarListagem() {
      this.getColaboradores()
      this.getColaboradoresInativos()
    },

    handleAction(item, action) {
      this.confirmDialogItem = item
      this.confirmDialogAction = action

      this.setDialogMessage({
        title:
          action === 'ativar'
            ? 'Ativação'
            : 'Desativação',
        message: `Deseja realmente ${action} o colaborador?`,
      })
    },
  },
}
</script>
