<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Colaboradores">
          <form-editar-colaborador
            v-if="editColaborador"
            :colaborador="editColaborador"
            @close="editColaborador = false"
          />
          <form-adicionar-usuario
            v-else-if="showForm"
            @close="
              showForm = false
              editColaborador = false
            "
          />
          <listar-colaboradores
            v-else
            @showForm="showForm = true"
            @editColaborador="editarColaborador"
            :empresa="empresa"
          />
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListarColaboradores from '@/components/gerencial/colaboradores/ListarColaboradores'
import FormAdicionarUsuario from '@/components/gerencial/colaboradores/adicionarUsuario/FormAdicionarUsuario'
import FormEditarColaborador from '@/components/gerencial/colaboradores/editarColaborador/FormEditarColaborador'


export default {
  // router param
  props: ['empresa'],

  data() {
    return {
      showForm: false,
      editColaborador: false,
      page: {
        title: 'Gerenciamento de usuários',
      },
      breadcrumbs: [
        {
          text: 'Geral',
          disabled: false,
          to: '/gerencial/colaboradores',
        },
        {
          text: 'Colaboradores',
          disabled: true,
        },
      ],
    }
  },
  components: {
    FormAdicionarUsuario,
    ListarColaboradores,
    FormEditarColaborador,
  },
  methods: {
    editarColaborador(colaborador) {
      this.editColaborador = colaborador
    },
  },
}
</script>

<style></style>
