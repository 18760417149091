<template>
  <v-row>
    <v-col cols="12">
      <v-card class="mb-5">
        <v-card-text class="pa-5 border-bottom">
          <validation-observer ref="observer">
            <h3
              class="title blue-grey--text text--darken-2 font-weight-regular"
            >
              Grupos
            </h3>
            <v-row>
              <v-col class="12" sm="6" lg="6">
                <v-select
                  label="Grupo de segurança"
                  :items="grupos"
                  v-model="grupo"
                  item-text="nome"
                  item-value="public_id"
                ></v-select>
              </v-col>
              <v-col class="12" sm="6" lg="6">
                <v-select
                  multiple
                  label="Perfis"
                  :items="perfisEmpresaColaborador"
                  v-model="perfis"
                  item-text="nome"
                  item-value="cod"
                ></v-select>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer />
              <buttons-cadastro @close="close" @save="save" />
            </v-card-actions>
          </validation-observer>
        </v-card-text>
      </v-card>
      <trocar-senha isManager :usuario="value.user.public_id"/>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import Perfil from "@/utils/perfil"
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro'
import TrocarSenha from '@/components/usuarios/TrocarSenha'
import TenantsAPI from '@/services/tenants'
export default {
  name: 'dadosUsuarioEmpresa',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  components: { ButtonsCadastro, TrocarSenha },
  data() {
    return {
      grupo: this.value.grupo,
      perfis: this.value.perfis,
      perfisEmpresaColaborador: []
    }
  },
  async created() {
    const empresa = await TenantsAPI.getEmpresaByPublicID(this.value.empresa)
    this.perfisEmpresaColaborador = Object.entries(Perfil).map(([nome, cod]) => ({
      nome,
        cod
      })).filter(i => empresa.tiposContrato.includes(i.cod))
    await this.getGrupos(empresa)
  },
  computed: {
    ...mapState('gerencial', ['grupos']),
  },
  methods: {
    ...mapActions('gerencial', ['getGrupos', 'updateGrupoColaborador']),
    ...mapActions('template', ['successMessage', 'errorMessage']),
    handleGrupo() {
      this.$emit('input', this.grupo)
    },
    async save() {
      try {
        await this.updateGrupoColaborador({
          colaborador: this.value,
          grupo: this.grupo,
          perfis: this.perfis
        })
        this.successMessage('Grupo atualizado com sucesso!')
      } catch (e) {
        this.errorMessage(e.response.data)
        this.grupo = this.value.grupo
      }
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding: 0;
}
</style>
